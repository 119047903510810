import { Button, Grid, Input } from '@mui/material';
import React, { useId, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Camera } from '../../assets/icons/camera.svg';

export default function AlignmentInput() {
  const [topic, setTopic] = useState('');
  const [objectUrl, setObjectUrl] = useState('');
  const inputId = useId();
  const navigate = useNavigate();

  const [{ isOver, isDragging }, drop] = useDrop<
    {
      files: File[];
      dataTransfer: DataTransfer;
    },
    void,
    {
      isOver: boolean;
      canDrop: boolean;
      isDragging: boolean;
    }
  >(
    () => ({
      accept: [NativeTypes.FILE],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        isDragging: monitor.getItem() !== null,
      }),
      drop(item: { files: File[] }) {
        const file = item.files?.[0];
        if (file) {
          setObjectUrl(URL.createObjectURL(file));
        }
      },
    }),
    [],
  );

  return (
    <Grid container>
      <Grid
        item
        container
        sx={[
          {
            alignItems: 'end',
            padding: '16px 20px 16px 20px',
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            backgroundColor: '#F6F6FA',
          },
        ]}
        ref={drop}
      >
        {objectUrl && (
          <img
            src={objectUrl}
            alt="object"
            style={{
              width: 'auto',
              alignSelf: 'top',
              height: '2.8rem',
              objectFit: 'contain',
              marginRight: '20px',
              marginBottom: '5px',
              borderRadius: '5px',
            }}
          />
        )}

        <Grid item sx={{ flex: 1 }}>
          <Input
            type="text"
            multiline
            fullWidth
            placeholder={
              isDragging
                ? 'Drop image here'
                : 'Enter a topic, text, URL, or image'
            }
            value={topic}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            onKeyUp={(event) => {
              event.stopPropagation();
            }}
            onChange={(event) => {
              setTopic(event.target.value);
            }}
            autoFocus
            sx={[
              {
                fontSize: '14px',
                color: 'black',
                fontWeight: 'bold',
                fontFamily: 'Inter',
                borderBottom: 'none',
                borderRadius: '5px',
                padding: 0,
                lineHeight: '26px',

                'textarea::placeholder': {
                  color: 'rgba(0, 0, 0, 0.6)',
                },
              },

              isOver && {
                borderColor: 'gray',
              },
            ]}
          />
        </Grid>
        <Grid item>
          <label htmlFor={inputId}>
            <Button
              variant="text"
              component="span"
              startIcon={<Camera />}
              style={{
                textTransform: 'none',
                lineHeight: '12px',
                padding: '0px',
              }}
            >
              Upload Image
            </Button>
            <input
              id={inputId}
              type="file"
              accept="image/*"
              style={{
                display: 'none',
              }}
              onChange={(event) => {
                const file = event.target.files?.[0];
                if (file) {
                  setObjectUrl(URL.createObjectURL(file));
                }
              }}
            />
          </label>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        style={{
          height: '48px',
          width: '81px',
          margin: 'auto',
          marginTop: '64px',
          textTransform: 'none',
        }}
        onClick={() => {
          navigate(`/document/align?topic=${topic}&objectDataUrl=${objectUrl}`);
        }}
      >
        Next
      </Button>
    </Grid>
  );
}
