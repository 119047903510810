import { Chip, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SelectableDocumentFormats } from 'src/configuration/documents';
import { useResource } from 'src/hooks/api';
import { useUserStore } from 'src/hooks/zustand/user';
import * as AssignmentsAPI from '../../api/Assignments';
import * as DocumentsAPI from '../../api/Documents';
import { Chevron } from '../../assets/icons/variantComponents';
import AssignmentButton from '../../components/AssignmentButton';
import PresstoButton from '../../components/PresstoButton';
import { lang } from '../../lang';
import AlignmentInput from './AlignmentInput';
import NewAssignmentCard from './NewAssignmentCard';
import { PresstoGrid } from './styles';
import BaseDialog, {
  DialogContent,
  DialogTitle,
} from '../../components/dialogs/BaseDialog';

const SORT_BY = ['recent', 'oldest', 'az', 'za'] as const;

export default function GalleryTab() {
  // const documents = useDocumentStore((state) => state.documents);
  const user = useUserStore((state) => state.user);
  const [sortedBy, setSortedBy] = useState<string>(SORT_BY[0]);
  const documentParams = useMemo(
    () => ({
      order: sortedBy,
    }),
    [sortedBy],
  );

  const theme = useTheme();
  const mediaSm = useMediaQuery(theme.breakpoints.up('sm'));
  const mediaMd = useMediaQuery(theme.breakpoints.up('md'));
  const mediaLg = useMediaQuery(theme.breakpoints.up('lg'));
  const mediaXl = useMediaQuery(theme.breakpoints.up('xl'));

  const [search, setSearch] = useSearchParams();

  const documents = useResource(DocumentsAPI.getAll, documentParams);
  const assignments = useResource(AssignmentsAPI.getAll);

  const typeFilter = search.get('type');
  const cards = [
    ...(documents?.map((document) => ({
      type: 'document' as const,
      ...document,
    })) || []),
    ...(assignments?.map((assignment) => ({
      type: 'assignment' as const,
      ...assignment,
    })) || []),
  ].filter((card) => {
    if (typeFilter) {
      return card.type === typeFilter;
    }
    return true;
  });

  cards.sort((a, b) => {
    if (sortedBy === 'az') {
      return a.name?.localeCompare(b.name || '') || 1;
    }
    if (sortedBy === 'za') {
      return b.name?.localeCompare(a.name || '') || 1;
    }
    if (sortedBy === 'recent') {
      return b.created_at.localeCompare(a.created_at);
    }
    if (sortedBy === 'oldest') {
      return a.created_at.localeCompare(b.created_at);
    }
    return 0;
  });

  // Specify behavior of the sort widget.
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const sortedByLabel = useMemo(
    () =>
      lang(
        `gallery.sort_select.${
          SORT_BY.find((el) => el === sortedBy) ?? SORT_BY[0]
        }`,
      ),
    [sortedBy],
  );
  const handleOpenSortByDialog = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSortByDialog = (selectedItem?: string) => {
    if (selectedItem) {
      setSortedBy(selectedItem);
      localStorage.setItem('sortDocuments', selectedItem);
    }
    setAnchorEl(null);
  };

  // Declare the templates to be shown. Templates are shortcuts
  // for creating documents.
  const formatFilter = SelectableDocumentFormats.find(
    (f) => f === search.get('templateFormat'),
  );

  let templatePageSize = 2;

  if (mediaSm) {
    templatePageSize = 3;
  }
  if (mediaMd) {
    templatePageSize = 4;
  }
  if (mediaLg) {
    templatePageSize = 5;
  }
  if (mediaXl) {
    templatePageSize = 6;
  }
  const templatePage = Number(search.get('page')) || 1;
  let begin = (templatePage - 1) * templatePageSize;

  // Pretend as though the '+ Blank' button is the first item in the
  // list.
  if (!formatFilter) {
    if (begin > 0) {
      begin -= 1;
    }
  }
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <div
      style={{
        overflowY: 'auto',
      }}
    >
      <BaseDialog
        onClose={() => {
          setIsDialogOpen(false);
        }}
        size="large"
        open={isDialogOpen}
      >
        <DialogTitle style={{ padding: '65px 65px 0 65px' }}>
          New Assignment
        </DialogTitle>
        <DialogContent style={{ paddingTop: '40px' }}>
          <AlignmentInput />
        </DialogContent>
      </BaseDialog>
      <PresstoGrid>
        <div>
          <div className="pressto-count-row">
            <div className="pressto-count">
              <strong>Your Files</strong>
              <div className="filters">
                {[
                  { label: 'Assignments', value: 'assignment' },
                  { label: 'Presstos', value: 'document' },
                ].map((type) => (
                  <Chip
                    key={type.value}
                    label={type.label}
                    variant="filled"
                    onClick={() => {
                      const newSearch = new URLSearchParams(search);
                      if (type.value === typeFilter) {
                        newSearch.delete('type');
                      } else {
                        newSearch.set('type', type.value);
                      }
                      setSearch(newSearch);
                    }}
                    color={type.value === typeFilter ? 'primary' : 'default'}
                  />
                ))}
              </div>
            </div>
            <div className="sorted-by">
              <span>{lang('general.organize_by')}</span>
              <u
                id="sort-by-button"
                aria-controls={open ? 'sorted-by-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                role="button"
                onClick={handleOpenSortByDialog}
                onKeyDown={handleOpenSortByDialog}
                tabIndex={-1}
                style={{ cursor: 'pointer' }}
              >
                {sortedByLabel} <Chevron direction={open ? 'up' : 'down'} />
              </u>
              <Menu
                id="sorted-by-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleCloseSortByDialog()}
                MenuListProps={{ 'aria-labelledby': 'sort-by-button' }}
                classes={{ paper: 'order-by-container', list: 'order-by-menu' }}
              >
                {SORT_BY.map((el) => (
                  <MenuItem
                    key={el}
                    onClick={() => handleCloseSortByDialog(el)}
                    style={el === sortedBy ? { fontWeight: 700 } : {}}
                  >
                    {lang(`gallery.sort_select.${el}`)}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
          <div className="content">
            {user.role !== 'student' && (
              <NewAssignmentCard
                onClick={() => {
                  setIsDialogOpen(true);
                }}
              />
            )}

            {cards?.map((pressto) => {
              if (pressto.type === 'assignment') {
                return (
                  <AssignmentButton
                    key={`pressto-${pressto.id}`}
                    assignment={pressto as any}
                    onDeleted={assignments?.$reload}
                  />
                );
              }
              return (
                <PresstoButton
                  key={pressto.id}
                  pressto={pressto as any}
                  onDeleted={documents?.$reload}
                />
              );
            })}
          </div>
        </div>
      </PresstoGrid>
    </div>
  );
}
