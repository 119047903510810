import { useTheme } from '@mui/material';
import React from 'react';
import { ButtonContainer } from 'src/components/AssignmentButton/styles';

type Props = {
  onClick?: () => void;
};

export default function NewAssignmentCard({ onClick }: Props) {
  const theme = useTheme();

  return (
    <div
      onClick={onClick} // Optional onClick handler
      style={{
        textDecoration: 'none',
      }}
    >
      <ButtonContainer
        elevation={16}
        className="create"
        sx={{
          '&:hover .info-container': {
            backgroundColor: '#F0F0F477',
          },
        }}
      >
        <div className="info-container">
          <div>{/* Spacer. Info container is justify-between. */}</div>
          <div
            style={{
              fontSize: '1em',
            }}
          >
            <span
              style={{
                color: theme.palette.primary.main,
                fontSize: '36px',
                fontWeight: 300,
              }}
            >
              +
            </span>
            <br />
            New
            <br />
            Assignment
          </div>
          <div />
        </div>
      </ButtonContainer>
    </div>
  );
}
