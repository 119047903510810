import { Box } from '@mui/material';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Editor as WYSIWYG } from 'react-draft-wysiwyg';
import {
  draft2html,
  generateBlocksStyleMap,
} from 'src/components/elements/TextBox/utils';
import { getBlockType } from 'src/configuration/blocks';
import { useBlockEditorState, useSelectedBlock } from 'src/hooks/document';
import { Block, BlockProperties, BlockType } from 'src/types/models';
import { debounce, theme } from 'src/utils';
import { FONT_SIZES } from '../../Toolbar/utils';

export const defaultStyleMap = generateBlocksStyleMap();
const defaultPlaceholder = 'Click to start writing';
const minimumFontSize = `${FONT_SIZES[0].em}em`;

/**
 * This component defines the full text editing experience for a block.
 * Not all blocks offer text editing, but all of those that do should use
 * this component. The Text component is responsible for:
 *
 * - Rendering and managing the state of the rich text editor, including global
 *   editing state that is used by the toolbar (a separate component).
 * - Converting to and from all serialization formats used to store the text
 * - Text sizing and style for rendering in web browser. The component is not
 *   responsible for figuring out how text is printed, but it is responsible
 *   for displaying text that is read-only.
 */
export default function Text(props: {
  block: Block;
  blockType: BlockType;
  isSelected: boolean;
  isEditable?: boolean;
  autoFocus?: boolean;
  onChange: (content: BlockProperties) => void;
  onOverflow: () => void;
  handleFitFontSize: () => void;
}) {
  const {
    block,
    blockType,
    isEditable = false,
    autoFocus,
    isSelected,
    onChange,
    onOverflow,
    handleFitFontSize,
  } = props;

  const placeholder = isEditable
    ? block.properties.placeholder ||
      blockType.configuration.placeholder?.toString() ||
      defaultPlaceholder
    : undefined;
  // The state management here is mind-bendy, be warned.
  // Blocks use DraftJS and react-draft-wysiwyg for rich text editing.
  // There are multiple blocks on the screen at a time, and each has its own
  // editor state. But, there is only one text toolbar on screen at
  // any given time. This means that the particular editor state for the
  // currently selected block must be accessible to the toolbar.
  // There are a few ways to accomplish this. The approach below is to
  // maintain one "selected editor" state in the shared document store,
  // while each block maintains its own editor state locally.
  const [editorState, setEditorState] = useBlockEditorState(
    // Define the initial state of the editor. If the block has multiple
    // Text components, a label can be added to the id to differentiate.
    block.id,
    typeof block.properties.rawEditorContent === 'string'
      ? EditorState.createWithContent(
          ContentState.createFromText(placeholder ?? ''),
        )
      : EditorState.createEmpty(),
  );
  const [, selectBlockId] = useSelectedBlock();

  // Track focus on the editor to prevent moving the cursor if we already
  // have a cursor position.
  const [hasFocus, setHasFocus] = useState(false);

  // If autoFocus is true, focus the editor and set the cursor at the end.
  useEffect(() => {
    if (autoFocus && !hasFocus) {
      setEditorState((prevEditorState: EditorState) =>
        EditorState.moveFocusToEnd(prevEditorState),
      );
    }
  }, [autoFocus]);

  useEffect(() => {
    if (typeof block.properties.rawEditorContent === 'string' && placeholder) {
      setEditorState(
        EditorState.createWithContent(ContentState.createFromText(placeholder)),
      );
    }
  }, [placeholder]);

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);

    const contentState = convertToRaw(newEditorState.getCurrentContent());
    const htmlText = draft2html(contentState, defaultStyleMap);
    const plainText = newEditorState.getCurrentContent().getPlainText();

    onChange({
      plainText,
      htmlText,
      rawEditorContent: JSON.stringify(contentState),
    });
  };

  // Use the default font size when no text is present. This prevents
  // the placeholder text from changing size and becoming unreadable.
  const { properties: p } = block;
  const defaultFontSize = getBlockType(block).defaultProperties.fontSize;
  const baseStyle: Record<string, string | undefined> = {
    fontSize: (p.plainText ? p.fontSize : defaultFontSize) || '1em',
    fontFamily: p.fontFamily || "'Open Sans'",
    color: p.color || 'black',
  };
  const isAtMinimumFontSize = baseStyle.fontSize! <= minimumFontSize;

  const [hasTextOverflow, setHasTextOverflow] = useState(false);
  const textEditorRef = useRef<HTMLElement>(null);

  // Selector used to find the editor element.
  // The editor "input" is rendered as a content-editable div.
  const inputSelector = '.public-DraftEditor-content div';

  // Check if the editor input is bigger than its containing block element.
  // If so, style with a red outline and text overflow message.
  const checkBoundaries = () => {
    const textEditorElement = textEditorRef.current;
    if (textEditorElement) {
      const inputElement = textEditorElement.querySelector(
        inputSelector,
      ) as HTMLDivElement | null;
      if (inputElement) {
        // Compare text editor height with block height without padding
        const textEditorPadding =
          textEditorElement.offsetHeight -
          parseFloat(getComputedStyle(textEditorElement).paddingTop);
        if (inputElement.offsetHeight > textEditorPadding) {
          setHasTextOverflow(true);
        } else {
          setHasTextOverflow(false);
        }
      }
    }
  };

  useEffect(() => {
    // The debounce prevents a flickering that shows the text with the wrong size
    // before updating it to the correct size.
    debounce(
      () => {
        if (hasTextOverflow) {
          // The text is overflowing its container.
          // Call parent function to decrease the font size.
          onOverflow();
        } else {
          // The text is not overflowing its container.
          // Call parent function to check if it should increase the font size.
          handleFitFontSize();
        }
      },
      50,
      'fitFontSize',
    );
  }, [
    hasTextOverflow,
    block.properties.plainText,
    block.properties.maximumFontSize,
    block.properties.fontSize,
    block.properties.fitFontSize,
  ]);

  useEffect(() => {
    if (!textEditorRef.current) return;

    // An observer is needed because, on first render, the content may not have
    // have all the proper styles; therefore, it may not have the correct height.
    // The resize observer triggers when the editor height changes for any reason.
    const resizeObserver = new ResizeObserver(checkBoundaries);
    const draftElement = textEditorRef.current.querySelector(
      inputSelector,
    ) as HTMLElement;
    resizeObserver.observe(draftElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [textEditorRef.current]);

  const onKeyDown = (event: React.KeyboardEvent) => {
    // If the user presses up or left while at the beginning of the selection,
    // then move focus to the previous block.
    if (
      (event.key === 'ArrowUp' || event.key === 'ArrowLeft') &&
      editorState.isSelectionAtStartOfContent()
    ) {
      event.preventDefault();
      event.stopPropagation();
      selectBlockId(-1);
    }

    // If the user presses down or right while at the end of the selection,
    // then move focus to the next block.
    if (
      (event.key === 'ArrowDown' || event.key === 'ArrowRight') &&
      editorState.isSelectionAtEndOfContent()
    ) {
      event.preventDefault();
      event.stopPropagation();
      selectBlockId(1);
    }
  };

  // Changing the height of the block does not trigger the resize observer.
  // Observe it manually. Using a layout effect instead of an effect prevents
  // the overflow style from flickering when the block size changes.
  useLayoutEffect(checkBoundaries, [block.height, block.width]);

  return (
    <Box
      ref={textEditorRef}
      className="text-editor"
      onKeyDown={onKeyDown}
      sx={[
        {
          padding: '.5em .6em',
          height: '100%',
          position: 'relative',
        },
        hasTextOverflow &&
          isAtMinimumFontSize &&
          isSelected && {
            outline: `2px ${theme.palette.error.main} solid`,
            '&::after': {
              content: '"Text Overflow"',
              position: 'absolute',
              zIndex: 1,
              fontSize: '14px',
              fontWeight: 'bold',
              px: '10px',
              lineHeight: '24px',
              bottom: '-16px',
              left: '-2px',
              right: '-2px',
              width: 'fill-available',
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.contrastText,
            },
          },
      ]}
    >
      <WYSIWYG
        key={block.id}
        toolbarHidden
        placeholder={placeholder}
        editorState={editorState}
        wrapperStyle={{
          width: '100%',
          height: '100%',
          overflow: 'visible',
        }}
        editorStyle={{
          overflowY: isSelected ? 'visible' : 'hidden',
          minHeight: '100%',
          height: isSelected ? 'auto' : '100%',
          ...baseStyle,
        }}
        readOnly={!isEditable}
        onEditorStateChange={onEditorStateChange}
        onChange={checkBoundaries}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        customStyleMap={defaultStyleMap}
        stripPastedStyles
      />
    </Box>
  );
}
