import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GalleryTopBar from '../../components/GalleryTopBar';
import CreateClassroomDialog from '../../components/dialogs/CreateClassroomDialog';
import { useUserStore } from '../../hooks/zustand/user';
import ClassroomsTab from './ClassroomsTab';
import GalleryTab from './GalleryTab';

export default function Gallery() {
  const navigate = useNavigate();
  const { tab } = useParams();

  const user = useUserStore((state) => state.user);
  const { role } = user;

  const [isCreateClassroomDialogOpen, setIsCreateClassroomDialogOpen] =
    useState(false);

  useEffect(() => {
    if (
      tab &&
      tab !== 'documents' &&
      tab !== 'assignments' &&
      tab !== 'classrooms'
    ) {
      navigate('/gallery/documents');
    }
  }, [tab]);

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: '#F0F0F4',
        overflow: 'hidden',
      }}
    >
      <GalleryTopBar tab={tab ?? 'documents'} />

      {(tab === 'documents' || tab === undefined) && <GalleryTab />}

      {tab === 'classrooms' && (
        <ClassroomsTab
          onCreateClassroomButtonClick={() =>
            setIsCreateClassroomDialogOpen(true)
          }
        />
      )}

      {role === 'teacher' && (
        <CreateClassroomDialog
          hasGoogleRedirect
          setTab={() => {}}
          isOpen={isCreateClassroomDialogOpen}
          onOpen={() => setIsCreateClassroomDialogOpen(true)}
          onClose={() => setIsCreateClassroomDialogOpen(false)}
        />
      )}
    </div>
  );
}
